/**
 * Format url to format appropriate for href
 *
 * @param       url     - URL
 * @returns     Formatted URL
 */
export const formatToUrl = (url?: string | null | undefined): string | null => {
  if (!url) return null;
  if (url.includes('//')) return url;
  return '//' + url;
};
