// Search City
export const searchIcon = '/images/search.svg';

// Offer Cards
export const boostGraphic = '/images/boost/boost-bg.png';
export const boostOfferGraphic = '/images/boost/boost-offer.png';
export const cashbackGraphic = '/images/cashback-bg.png';
export const cashForwardGraphic = '/images/cash-forward-bg.png';
export const cashOfferGraphic = '/images/cash-forward-offer.png';
export const locationIcon = '/images/location.svg';
export const roundupUrl = '/images/round-up.png';

// Offer overlay
export const offerOverlay = '/images/offer-verlay.png';
export const closeButton = '/images/x-button.png';

// Footer icons
export const facebook = '/images/socials/social-facebook.png';
export const instagram = '/images/socials/social-instagram.png';
export const leaside = '/images/socials/leaside.png';
export const twitter = '/images/socials/social-twitter.png';
export const linkedin = '/images/socials/linkedin.png';
export const genWell = '/images/genwell-sales/genwell-icon.png';

// Food bank social icons
export const fb_facebook = '/images/socials/social-fb.png';
export const fb_instagram = '/images/socials/social-ig.png';
export const fb_leaside = '/images/socials/social-leaside.png';
export const fb_hope_factory = '/images/socials/social-hf.png';

// Food initiative
export const iconWebSvg = '/images/food-initiative/icon-web.svg';
