
import { Component, Vue, Prop } from 'vue-property-decorator';

// Components
import { boostChip } from '@/components/offers/boosts';

// Config Variables
import { ActiveTenant } from '../../../../active-tenant';
import { tenants } from '../../../../tenant-config';

// Utilities
import { formatToUrl } from '@/utils/format-to-url';
import { iconWebSvg } from '@/utils/image-paths';

// Types
import { IBooster, IMatching } from '@/types/offer.types';

@Component({ components: { boostChip } })
export default class boostCard extends Vue {
  @Prop({ required: true })
  boost!: IBooster;

  iconWebSvg: string = iconWebSvg;
  boostRange: string = this.getBoostRange(this.boost.matchings ?? []);
  boostTitle: string = this.boostRange + ' Boost';

  boostRangeType: string = this.boostRange.includes('-') ? 'long-value-holder' : 'short-value-holder';

  accentBackground = tenants[ActiveTenant].background.boosterCardAccent;
  accentText = tenants[ActiveTenant].text.boosterCardAccent;
  iconAddress = tenants[ActiveTenant].iconAddress;
  iconPhone = tenants[ActiveTenant].iconPhone;
  iconWeb = tenants[ActiveTenant].iconWeb;

  formatToUrl = formatToUrl;

  getBoostRange(boostChips: IMatching[]): string {
    let values: number[] = [];
    for (let i in boostChips) values.push(boostChips[i].matchingValue);

    const maxBoost: string = Math.max(...values).toString();
    const minBoost: string = Math.min(...values).toString();

    return maxBoost === minBoost ? `$${maxBoost}` : `$${minBoost} - $${maxBoost}`;
  }

  getDomainUrl() {
    return new URL(this.boost.websiteUrl as string).host;
  }
}
